/* eslint-disable @typescript-eslint/no-var-requires */
/** @type {import('tailwindcss').Config} */

module.exports = {
  theme: {
    extend: {
      transitionDelay: {
        0: '0ms',
        400: '400ms',
        600: '600ms',
        800: '800ms',
        1200: '1200ms',
        1500: '1500ms',
        2000: '2000ms',
        3000: '3000ms',
      },
      animation: {
        'spin-normal-reverse': 'reverse-spin-verbose 30.5s linear infinite',
        'spin-1s': 'spin 1s linear infinite',
        'spin-2s': 'spin 2s linear infinite',
        'spin-10s': 'spin 10s linear infinite',
        'spin-60s': 'spin 60s linear infinite',
        'spin-120s': 'spin 120s linear infinite',
        'spin-180s': 'spin 180s linear infinite',
        'spin-60s-reverse': 'reverse-spin 60s linear infinite',
        'spin-120s-reverse': 'reverse-spin 120s linear infinite',
        'spin-180s-reverse': 'reverse-spin 180s linear infinite',
        'move-full-left': 'moveFullLeft 1ß0s linear infinite',
        'move-full-left-reversed': 'moveFullLeftReversed 10s linear infinite ',
        'card-scroll': 'cardScroll 90s linear infinite',
        'gradient-rotate': 'gradientRotate 1s linear infinite',
        'gradient-shift': 'gradientShift 30s linear infinite',
        'bg-move': 'bgMove 5s linear 2s infinite',
      },
      keyframes: {
        'reverse-spin-verbose': {
          '0%': {
            transform: 'rotate(0deg)',
          },
          '50%': {
            transform: 'rotate(180deg)',
          },
          '100%': {
            transform: 'rotate(360deg)',
          },
        },
        'reverse-spin': {
          from: {
            transform: 'rotate(360deg)',
          },
        },
        moveFullyLeft: {
          from: {
            left: '0%',
          },
          to: {
            left: '-100%',
          },
        },
        moveFullLeftReversed: {
          from: {
            left: '100%',
          },
          to: {
            left: '0%',
          },
        },
        cardScroll: {
          from: {
            transform: 'translateX(0%)',
          },
          to: {
            transform: 'translateX(-50%)',
          },
        },
        gradientRotate: {
          from: {
            backgroundPosition: '100% 0%',
          },
          to: {
            backgroundPosition: '0% 100%',
          },
        },
        gradientShift: {
          from: {
            backgroundPosition: '0 0',
          },
          to: {
            backgroundPosition: '300% 0',
          },
        },
        bgMove: {
          '0%': { backgroundPosition: '0% 0' },
          '50%': { backgroundPosition: '100% 0' },
          '100%': { backgroundPosition: '100% 0' },
        },
      },
    },
    variants: {
      extend: {
        borderWidth: ['hover', 'focus'],
        zIndex: ['hover'],
      },
    },
  },
};
