import resolveConfig from 'tailwindcss/resolveConfig';
import type { Config } from 'tailwindcss';
// @ts-ignore
import config from '../../tailwind.config.js';

const twConfig = resolveConfig(config);

const colors = twConfig.theme?.colors as Config;

export type TailwindColors = keyof typeof colors;

export const getTailwindColor = (color: TailwindColors) => {
  return colors && colors[color];
};
