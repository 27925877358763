/* eslint-disable @typescript-eslint/no-var-requires */
/** @type {import('tailwindcss').Config} */

module.exports = {
  theme: {
    fontFamily: {
      mono: ['Space Grotesk Mono', 'ui-sans-serif', 'system-ui', 'Helvetica', 'sans-serif'],
      sans: ['Space Grotesk', 'ui-sans-serif', 'system-ui', 'Helvetica', 'sans-serif'],
      serif: [
        'Space Grotesk',
        'ui-serif',
        'Georgia',
        'Cambria',
        'Times New Roman',
        'Times',
        'serif',
      ],
    },
    variants: {
      extend: {
        borderWidth: ['hover', 'focus'],
        zIndex: ['hover'],
      },
    },
  },
};
