/* eslint-disable @typescript-eslint/no-var-requires */
/** @type {import('tailwindcss').Config} */
/* eslint-disable */
const plugin = require('tailwindcss/plugin');

module.exports = {
  presets: [
    require('./styles/tailwind/coloring.js'),
    require('./styles/tailwind/animation.js'),
    require('./styles/tailwind/layout.js'),
    require('./styles/tailwind/utils.js'),
    require('./styles/tailwind/effects.js'),
    require('./styles/tailwind/font.js'),
  ],
  safelist: [
    {
      pattern: /heatwave[6|7]\/\b([1-9]|[1-9][0-9]|100)\b/g,
    },
  ],
  content: [
    './pages/**/*.{js,jsx,ts,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './presentational/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    screens: {
      xs: '320px',
      mobile: '390px',
      sm: '600px',
      tablet: '768px',
      md: '960px',
      md2: '1024px',
      lg: '1224px',
      lg2: '1374px',
      xl: '1524px',
      '2xl': '1920px',
      '3xl': '2560px',
    },
    container: {
      center: true,
      padding: '2rem',
      size: {
        xs: '320px',
        mobile: '390px',
        sm: '600px',
        tablet: '768px',
        md: '960px',
        lg: '1224px',
        xl: '1524px',
      },
    },
  },
  plugins: [
    plugin(function ({ matchUtilities, theme }) {
      matchUtilities(
        {
          'text-shadow': (value) => ({
            textShadow: value,
          }),
        },
        { values: theme('textShadow') }
      );
    }),
  ],
};
