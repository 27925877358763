/* eslint-disable @typescript-eslint/no-var-requires */
/** @type {import('tailwindcss').Config} */

module.exports = {
  theme: {
    extend: {
      blur: {
        '0.5xl': '30px',
        '3.5xl': '100px',
        '3.75xl': '150px',
        '4xl': '200px',
        '4.5xl': '250px',
      },
      boxShadow: {
        even: '0 0 5px rgba(0, 0, 0, 0.3)',
        'even-lg': '0 0 10px rgba(0, 0, 0, 0.3)',
        'even-xl': '0 0 15px rgba(0, 0, 0, 0.3)',
        'even-2xl': '0 0 20px rgba(0, 0, 0, 0.3)',
      },
      opacity: {
        7.5: '.075',
        15: '.15',
        25: '.25',
        35: '.35',
        45: '.45',
        55: '.55',
        65: '.65',
        75: '.75',
        85: '.85',
        95: '.95',
      },
    },
    variants: {
      extend: {
        borderWidth: ['hover', 'focus'],
        zIndex: ['hover'],
      },
    },
  },
};
