/* eslint-disable @typescript-eslint/no-var-requires */
/** @type {import('tailwindcss').Config} */

module.exports = {
  theme: {
    extend: {
      fontSize: {
        '3xs': '.55rem',
        '2xs': '.65rem',
        sm2: '.82rem',
        base2: '.9rem',
        lg2: '1.125rem',
        '2.5xl': '1.635rem',
        '3.5xl': '2rem',
        '4.5xl': '2.625rem',
        '5.5xl': '3.325rem',
        '7.5xl': '5.25rem',
      },

      padding: {
        4.5: '1.15rem',
        6.5: '1.6rem',
        7.5: '30px',
      },
      margin: {
        5.5: '1.4rem',
        9.5: '2.30rem',
        18: '4rem',
      },

      lineHeight: {
        4.5: '1.15rem',
        5.5: '1.30rem',
      },

      minHeight: {
        12: '3rem',
      },
      maxWidth: {
        '3.5xl': '50rem',
      },

      height: {
        13: '3.25rem',
        15: '3.75rem',
        18: '4.5rem',
        25: '6.25rem',
        26: '6.375rem',
      },
      width: {
        4.5: '1.125rem',
        5.5: '1.375rem',
        13: '3.25rem',
        18: '4.5rem',
        38: '9.5rem',
        42: '10.625rem',
        72: '18rem',
        84: '21rem',
        96: '24rem',
        100: '25rem',
        128: '32rem',
        192: '48rem',
        500: '500px',
        600: '600px',
        700: '700px',
        800: '800px',
        1000: '1000px',
      },

      scale: {
        '-1': '-1',
        32: '0.32',
        78: '0.78',
        102: '1.02',
        135: '1.35',
        200: '2',
      },

      borderWidth: {
        1: '1px',
        5: '5px',
        6: '6px',
      },
      borderRadius: {
        '0.5xl': '0.625rem',
        '1.5xl': '0.875rem',
        '2.5xl': '1.25rem',
        '3.5xl': '1.875rem',
      },
    },
    variants: {
      extend: {
        borderWidth: ['hover', 'focus'],
        zIndex: ['hover'],
      },
    },
  },
};
