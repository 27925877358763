/* eslint-disable @typescript-eslint/no-var-requires */
/** @type {import('tailwindcss').Config} */
/* eslint-disable */
const colors = require('tailwindcss/colors');

module.exports = {
  theme: {
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      black: '#000',
      white: '#fff',
      red: colors.red,
      green: colors.green,
      blue: colors.blue,
      purple: colors.purple,
      fuchsia: colors.fuchsia,
    },
    extend: {
      backgroundImage: {
        noise: "url('/images/landingpagev2/noisePattern.webp')",
        'blog-text-gradient':
          'radial-gradient(76.72% 54.27% at 50% 76.83%, #8A88FF 29.21%, #5EFCD6 100%)',
        'game-select':
          'linear-gradient(180deg, rgba(138, 136, 255, 0.30) 0%, rgba(138, 136, 255, 0.18) 100%)',
        'scroll-gradient':
          'linear-gradient(90deg, #FFE4AA 0%, #FFB382 14.29%, #E37790 28.57%, #5E5AED 42.86%, #5E5AED 57.14%, #E37790 71.43%, #FFB382 85.71%, #FFE4AA 100%)',
        'callout-gradient':
          'linear-gradient(180deg, #FFE5AA, #FFB383, #E37790, #5E5BED, #5EFCD6, #5E5BED, #E37790, #5E5BED, #5EFCD6, #5E5BED, #E37790, #FFE5AA, #E37790, #5E5BED, #5EFCD6, #E37790, #FFB383)',
        'conic-gradient':
          'conic-gradient(from 90deg at 50% 50.1%, #FFE5AA 0deg, #FFB383 45deg, #E37790 90deg, #5E5BED 135deg, #5EFCD6 180deg, #5E5BED 225deg, #E37790 270deg, #FFB382 315deg, #FFE4AA 360deg)',
        checkered:
          'linear-gradient(45deg, #000 25%, transparent 25%, transparent 75%, #000 75%), linear-gradient(45deg, #000 25%, transparent 25%, transparent 75%, #000 75%)',
        'alarm-gradient':
          'radial-gradient(119.9% 100% at 50% 0%, #FF98C1 0%, rgba(255, 114, 146, 0.99) 38.02%, #FC5E5E 100%)',

        'coldsnap-gradient':
          'linear-gradient(0deg, rgba(94, 252, 214, 0.16) 0%, rgba(94, 252, 214, 0.16) 100%), linear-gradient(0deg, rgba(14, 18, 47, 0.60) 0%, rgba(14, 18, 47, 0.60) 100%)',
        'tag-danger-hover':
          'radial-gradient(50.16% 50.16% at 50.16% 49.84%, #E37790 0%, rgba(227, 119, 144, 0.00) 100%)',
        'tag-warning-hover':
          'radial-gradient(50.16% 50.16% at 50.16% 49.84%, #FFB382 0%, rgba(255, 179, 130, 0.00) 100%)',
        'tag-coldsnap-hover':
          'radial-gradient(50.16% 50.16% at 50.16% 49.84%, #5EFCD6 0%, rgba(94, 252, 214, 0.00) 100%)',
        'tag-heatwave-hover':
          'radial-gradient(50.16% 50.16% at 50.16% 49.84%, #5E5AED 0%, rgba(94, 90, 237, 0.00) 100%)',

        'surface-panel': `
          linear-gradient(180deg, rgba(0, 0, 0, 0.51) 0%,  rgba(0, 0, 0, 0.45) 100%),
          linear-gradient(0deg, rgb(var(--primary)), rgb(var(--primary)))`,
        'surface-input-default':
          'linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%,  rgba(0, 0, 0, 0.54) 100%), linear-gradient(0deg, rgb(var(--primary)), rgb(var(--primary)))',
      },

      colors: {
        'functional-valid-light': '#A5FDB8',
        'functional-green': '#5EFC81',
        'functional-green-dark': '#1EB840',
        'functional-red': '#FC5E5E',
        'functional-red-dark': '#F03838',
        'functional-yellow': '#FCC75E',
        night0: '#E6E8F4',
        night1: '#B8BCE0',
        night2: '#7278A1',
        night3: '#4D5280',
        night4: '#353A62',
        night5: '#252B55',
        night6: '#1F244C',
        night7: '#0E122F',
        night8: '#0A0D24',
        night9: '#070918',
        heatwave1: '#FFFFFF',
        heatwave2: '#FFF8D2',
        heatwave3: '#FFE4AA',
        heatwave4: '#FFB382',
        heatwave5: '#E37790',
        heatwave6: '#8A88FF',
        heatwave7: '#5E5AED',
        coldsnap4: '#5EFCD6',
        cloud5: '#B6B6C4',
        cloud6: '#30C1FF',
        cloud7: '#419FE4',
        cloud8: '#2E2E5E',
        cloud9: '#0B0B1E',
        primary: 'rgb(var(--primary) / <alpha-value>)',
        accent: 'rgb(var(--accent) / <alpha-value>)',
        danger: 'rgb(var(--danger) / <alpha-value>)',
        buttonPrimary: 'rgb(var(--buttonPrimary) / <alpha-value>)',
        buttonSecondary: 'rgb(var(--buttonSecondary) / <alpha-value>)',
      },

      textShadow: {
        'coldsnap-shadow': '0px 0px 8px #5EFCD6, 0px -1px 1px rgba(0, 0, 0, 0.25)',
      },

      boxShadow: {
        inner: ' 0px 1px 1px 0px rgba(0, 0, 0, 0.10) inset',
        'white-underline': '0px 3px 0px #FFFFFF;',
        'email-modal': '0px 24px 34px rgba(0, 0, 0, 0.4)',
        'input-inset': 'inset 0px 2px 1px 0px rgba(0, 0, 0, 0.20)',
        'button-inset':
          '0px 3px 2px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(165, 163, 255, 0.30) inset',
        'game-select':
          '0px 3px 2px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(94, 90, 237, 0.24) inset',
        'button-inset-pressed': 'inset 0px 1px 1px 0px rgba(0, 0, 0, 0.30)',
        'button-shadow':
          '0px 3px 2px rgba(0, 0, 0, 0.1), inset 0px 2px 1px rgba(255, 255, 255, 0.3)',
        'select-shadow': '0px 3px 2px rgba(0,0,0,0.10)',
        'select-inset-shadow': 'inset 0px 1px 1px 0px rgba(255,255,255, 0.3)',
        'danger-shadow': '0px 0px 8px 0px #FC5E5E',
        'pressed-shadow': 'inset 0px 2px 1px 0px rgba(0, 0, 0, 0.35)',
        'room-card-hover': '0 0 20px rgba(94, 252, 214, 0.6)',
        'tag-shadow':
          '0px 1px 1px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 1px 0px rgba(138, 136, 255, 0.12)',
        'paginator-shadow':
          '0px 1px 1px 0px rgba(165, 163, 255, 0.30) inset, 0px 1px 1px 0px rgba(15, 16, 47, 0.20)',
        'paginator-selected-shadow':
          '0px 2px 2px 0px rgba(15, 16, 47, 0.20) inset, 0px 1px 1px 0px rgba(165, 163, 255, 0.30)',
        'coldsnap-shadow':
          '0px 0px 6px 0px rgba(94, 252, 214, 0.40), 0px 1px 1px 0px rgba(15, 16, 47, 0.20)',
        'blog-card-hover':
          '0px 14.26558px 20.3794px 0px rgba(0, 0, 0, 0.10), 0px 0px 20.3794px 0px #5CFCD5, 0px 2.03794px 1.01897px 0px rgba(255, 255, 255, 0.06) inset, 0px 0px 50.94851px 0px rgba(94, 252, 214, 0.50) inset',
        'step-inset':
          'inset 0 11px 7px 2px rgba(255,255,255,1), inset 0 -3px 2px 2px rgba(255,255,255,0.8)',
        'blog-avatar-shadow':
          '4px 8px 12px 0px rgba(15, 16, 47, 0.06), 4px 4px 6px 0px rgba(15, 16, 47, 0.06)',
        'tag-danger':
          '0px 1px 1px 0px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(227, 119, 144, 0.30) inset',
        'tag-warning':
          '0px 1px 1px 0px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(255, 179, 130, 0.30) inset',
        'tag-coldsnap':
          '0px 1px 1px 0px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(94, 252, 214, 0.30) inset',
        'tag-heatwave':
          '0px 1px 1px 0px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(138, 136, 255, 0.30) inset',
        'tag-success':
          '0px 1px 1px 0px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(94, 252, 129, 0.30) inset',
      },
    },
    variants: {
      extend: {
        borderWidth: ['hover', 'focus'],
        zIndex: ['hover'],
      },
    },
  },
};
