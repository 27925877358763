/* eslint-disable @typescript-eslint/no-var-requires */
/** @type {import('tailwindcss').Config} */

module.exports = {
  theme: {
    extend: {
      cursor: {
        'close-modal': 'url(/common/icons/close-modal-cursor.webp) 22 22, pointer',
      },
      content: {
        step: 'url("/assets/community/elements/step_number_bg.png")',
      },
    },
    variants: {
      extend: {
        borderWidth: ['hover', 'focus'],
        zIndex: ['hover'],
      },
    },
  },
};
